import React, { useEffect, useState } from 'react';
import AlgoConnect from './../algo/connect';

const Header = () => {
    return (
      <>
        <div className="logo"><a href="/"><img src="/logo.png" width="300px"/></a></div>
      </>
  );

}

export default Header;
