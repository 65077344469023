import React, { useEffect, useState } from 'react';

const Footer = () => {
    return (
      <>
        <div className="footer col">
            <div className="container d-block text-center pt-2 pb-2">
                <p className="mt-2 mb-1">&copy; Copyright 2022 World of Waifuz</p>
                <p className="mb-2">Made with <i className="bi bi-heart-fill"></i> by Pixie</p>
            </div>
        </div>
      </>
  );

}

export default Footer;
