import { Button } from "@hipo/react-ui-toolkit";
import { useEffect, useState } from "react";
import { PeraWalletConnect } from "@perawallet/connect";
import MyAlgoWallet, { SignedTx } from '@randlabs/myalgo-connect';
import { useForm } from "react-hook-form";
import algosdk from 'algosdk';
import axios from 'axios';
import Entries from './entries';
import AlgoConnect from './../components/algo/connect';

import {
  generateAssetTransferTxns,
  generateOptIntoAssetTxns,
  generatePaymentTxns
} from "../utils/txnUtils";
import { truncateAccountAddress } from "../utils/stringUtils";
import algod from "../utils/algod";

// shouldShowSignTxnToast is by default true,
// you can set it to false to disable the guidance completely
const peraWallet = new PeraWalletConnect({ shouldShowSignTxnToast: true });
const myAlgoWallet = new MyAlgoWallet();
const algodClient = new algosdk.Algodv2('', 'https://node.algoexplorerapi.io', '');

function Raffle() {
  const [accountAddress, setAccountAddress] = useState<string | null>(null);
  const isConnectedToPeraWallet = !!accountAddress;
  const [isRequestPending, setIsRequestPending] = useState(false);
  const [assets, setAssets] = useState<any[]>([]);
  const [value, setValue] = useState('');
  const [ticket, setTicket] = useState('');

  //
  // this is just for demo purposes, no need to in real-life apps
  const [logs, setLogs] = useState<string[]>([]);

  const handleChange = event => {
		setTicket(event.target.value);
	};
  //

  useEffect(() => {
  	const options = {
            method: 'GET',
            url: 'https://api.airtable.com/v0/appGnHnZE6KMzSQV2/RafflePrize',
			headers: { Authorization: "Bearer keygNO3JKTMATlw81" }
        }

        axios.request(options).then((response) => {
            setAssets(response.data.records)

        }).catch((error) => {
            console.error(error)
        })

    peraWallet
      .reconnectSession()
      .then((accounts) => {
        if (accounts.length) {
          setAccountAddress(accounts[0]);
          handleSetLog("Connected to Pera Wallet");
        }

        peraWallet.connector?.on("disconnect", () => {
          setAccountAddress(null);
        });
      })
      .catch((e) => console.log(e));
  }, []);

  	async function add(assets) {
		try {
			let selectedwallet = localStorage.getItem('statuss');
			if (selectedwallet) {
				let rawasset = assets.fields.assetid;
				let assetid = localStorage.setItem('assetid', rawasset);
				let rawassetname = assets.fields.nftname;
				let assetname = localStorage.setItem('assetname', rawassetname);
                let rawprice = assets.fields.price;
                let price = localStorage.setItem('price', rawprice);
				handleSignMultipleTransactions();
			} else {
				alert("Please connect your wallet!");
			}
		} catch (err) {
  			console.error(err);
  	  	}
	}

	async function handleSignMultipleTransactions() {
		const rawasset = localStorage.getItem("assetid") || "{}";
		const assetid1 = parseInt(rawasset);
		const ticketamount = parseInt(ticket);
  		const assetid2 = 790932174;
        const enc = new TextEncoder();
        const noted = enc.encode(String(assetid1));
		const addresses = localStorage.getItem("address");
  		const receiver = "MVHNF75VFQBKJYBSAMV56VEKWNRRHGIL2JGY5NMIH4Y7EQA6SX4BECYWDE";
  		const amounts = ticketamount;
        const rawpriced = localStorage.getItem("price") || "{}";
        const price = parseInt(rawpriced);
        const finalamount = amounts*price;


		if (localStorage.getItem("walletType") === "MyAlgo") {
			const params = await algodClient.getTransactionParams().do();
			const txn1 = algosdk.makeAssetTransferTxnWithSuggestedParamsFromObject({
	  			suggestedParams: {
	  				...params,
	  			},
	  			from: addresses,
	  			to: addresses,
	  			assetIndex: assetid1,
	  			amount: 0,
	  		})

	  		const txn2 = algosdk.makeAssetTransferTxnWithSuggestedParamsFromObject({
	  			suggestedParams: {
	  				...params,
	  			},
	  			from: addresses,
	  			to: receiver,
	  			assetIndex: assetid2,
	  			amount: finalamount,
                note: noted,
	  		})

		    // Sign and send transactions
			const myAlgoWallet = new MyAlgoWallet();
			const txnsArray = [ txn1, txn2 ];
			const groupID = algosdk.computeGroupID(txnsArray)
			for (let i = 0; i < 2; i++) txnsArray[i].group = groupID;
			const signedTxns = await myAlgoWallet.signTransaction(txnsArray.map(txn => txn.toByte()));
			await algodClient.sendRawTransaction([signedTxns[0].blob,signedTxns[1].blob]).do();
			alert("Transaction success!");
			window.location.href = window.location.href;
		} else {
			setIsRequestPending(true);
			const optinTxGroups = await generateOptIntoAssetTxns({
			  	assetID: assetid1,
			  	initiatorAddr: accountAddress!
			});

			const transferTxGroups = await generateAssetTransferTxns({
			  	to: receiver,
			  	assetID: assetid2,
			  	amount1: finalamount,
                notes: noted,
			  	initiatorAddr: accountAddress!
			});

			handleSetLog("Transfer txns generated: Launch Pera Wallet to sign them");
			try {
		  		const signedTxnGroups = await peraWallet.signTransaction([
		    		optinTxGroups,
		    		transferTxGroups
		  		]);

		 		console.log(signedTxnGroups);

		  		// Sign every txn in the group
			  	for (const signedTxnGroup of signedTxnGroups) {
			    	const { txId } = await algod.sendRawTransaction(signedTxnGroup).do();
			    	alert("Transaction success!");
			  	}
			} catch (error) {
		  		handleSetLog(`${error}`);
			} finally {
			  	setIsRequestPending(false);
			}
		}

	}

  	function handleSetLog(log: string) {
		setLogs((prevState) => [log, ...prevState]);
  	}

  return (
    <>
    <div className="container-md">
    	<div className="row justify-content-center odd-row mt-5 mb-5">
	            <div className="text-center mt-2 mb-2">
	                <h1 className="heading-title">Holders Raffles</h1>
	            </div>
            </div>
            <div className="d-flex justify-content-between section-2">
	            <div className="text-center">
	            </div>
                <div className="text-center"><AlgoConnect />
               </div>
            </div>
    		<div className="row raffle-page">
                {assets?.map((assets) => (
                    <div className="raffle-list text-center" key={assets.fields.assetid}>
                        <div className="product">
                            <div className="d-flex justify-content-between mb-2">
                                <h6 className="mb-2" aria-label={assets.fields.nftname}>{assets.fields.nftname}</h6>
                                <h6 className="mb-2">{assets.fields.assetid}</h6>
                            </div>
                            <div className="product-image" aria-label={assets.fields.assetid}>
                                <img className="mb-3" src={`https://ipfs.io/ipfs/${assets.fields.cid}`} width="100%"/>
                            </div>
                            <div className="d-flex justify-content-between fieldvalue">
                            <h6 className="mb-2">Price:</h6>
                            <h6 className="mb-2">{assets.fields.price} MUFFIN</h6>
                            </div>
                            <div className="d-flex justify-content-between fieldvalue">
                            <h6 className="mb-2">END DATE:</h6>
                            <h6 className="mb-2 f">{assets.fields.enddate}</h6>
                            </div>
                            <div className="d-flex justify-content-between fieldvalue">
                                <h6>Winner: </h6>
                                <h6 className="mb-2">{assets.fields.winner}</h6>
                            </div>
                            <label className={"mb-3 mt-3 " + assets.fields.status}>Ticket amount:</label>
                            <input
                                className={"form-control mb-3 " + assets.fields.status}
                                type="number"
                                min="0"
                                max="5000"
                                id="ticket"
                                name="ticket"
                                onChange={handleChange}
                                autoComplete="off"
                                required
                                />
                                <Button
                                customClassName={"action-button-out text-center mb-3 " + assets.fields.status}
                                onClick={() => add(assets)}
                                >Buy Raffle Ticket</Button>
                        </div>
                    </div>
                ))}
            </div>
            <div className="mb-5">
            	<h3 className="mb-3">Latest Transaction:</h3>
            		<iframe className="tx-list" src="https://transaction-970.pages.dev/" width="100%" height="450" />
            </div>
    </div>
    </>
  );
}

export default Raffle;
