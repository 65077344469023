import React, { useEffect, useState } from 'react';
import MyAlgoWallet, { SignedTx } from '@randlabs/myalgo-connect';
import { PeraWalletConnect } from "@perawallet/connect";
import { useForm } from "react-hook-form";
import algosdk from 'algosdk';
import axios from 'axios';
import Dropdown from 'react-bootstrap/Dropdown';
import { Button } from "@hipo/react-ui-toolkit";
import { truncateAccountAddress } from "../../utils/stringUtils";

const myAlgoWallet = new MyAlgoWallet();
const peraWallet = new PeraWalletConnect();

const AlgoConnect = () => {

  const [accountAddress, setAccountAddress] = useState<string | null>(null);
  const isConnectedToPeraWallet = !!accountAddress;
  const [sliceaddress, setSliceaddress] = useState(localStorage.getItem('sliceaddress'));
   //
  // this is just for demo purposes, no need to in real-life apps
  const [logs, setLogs] = useState<string[]>([]);
  //



  useEffect(() => {
    peraWallet
      .reconnectSession()
      .then((accounts) => {
        if (accounts.length) {
          setAccountAddress(accounts[0]);

          handleSetLog("Connected to Pera Wallet");
        }

        peraWallet.connector?.on("disconnect", () => {
          setAccountAddress(null);
        });
      })
      .catch((e) => console.log(e));
  }, []);

  const connectToMyAlgo = async() => {
    try {
      const accounts = await myAlgoWallet.connect();
      const address = accounts.map(account => account.address);
      localStorage.setItem('statuss', address[0]);
      localStorage.setItem('address', address[0]);
      localStorage.setItem('walletType', "MyAlgo");
      localStorage.setItem('sliceaddress', address[0].slice(0, 4)+'....'+address[0].slice(-4));
      window.location.href = window.location.href;
    } catch (err) {
      console.error(err);
    }
  }

  async function handleConnectWalletClick() {
    try {
      const newAccounts = await peraWallet.connect();
      localStorage.setItem('statuss', newAccounts[0]);
      setAccountAddress(newAccounts[0]);
      localStorage.setItem('walletType', "Pera");
      window.location.href = window.location.href;
       } catch (error) {
        handleSetLog(`${error}`);
      } finally {
        console.log("success");
      }
  }

  function handleDisconnectWalletClick() {
    peraWallet.disconnect();
    setAccountAddress(null);
    let keysToRemove = ["address", "balance", "sliceaddress", "statuss"];
      keysToRemove.forEach(k =>
        localStorage.removeItem(k));
    if (localStorage.getItem("walletType") === "MyAlgo") {
    window.location.href = window.location.href; }
  }

  function handleSetLog(log: string) {
    setLogs((prevState) => [log, ...prevState]);
  }

  if (localStorage.getItem("statuss") === null) {
    return (
    <>
      <Dropdown>
        <Dropdown.Toggle  variant="danger" className="btn-connect" id="dropdown-basic">
        Connect Wallet
        </Dropdown.Toggle>
        <Dropdown.Menu>
        <Dropdown.Item onClick={connectToMyAlgo}>MyAlgo</Dropdown.Item>
        <Dropdown.Item onClick={handleConnectWalletClick}>PeraWallet</Dropdown.Item>
       
        </Dropdown.Menu>
    </Dropdown>
    </>
  );
  }
   return (
    <>
    <Dropdown>
        <Dropdown.Toggle  variant="danger" className="btn-connect" id="dropdown-basic">
         {accountAddress && (
          <span className={"app__account-address"}>{`${truncateAccountAddress(accountAddress)}`}</span>
          )}
         <span>{sliceaddress}</span>
        </Dropdown.Toggle>
        <Dropdown.Menu>
            <Dropdown.Item onClick={handleDisconnectWalletClick}>Disconnect</Dropdown.Item>
        </Dropdown.Menu>
   </Dropdown>
    </>
  );

}

export default AlgoConnect;
