import React from 'react';
import Header from './components/header';
import Footer from './components/footer';
import { BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import Home from './pages';
import Raffle from './pages/raffle';

function App() {
return (
  <>
      <Router>
        <Header />
        <Routes>
          <Route path='/' element={<Home />} />
        </Routes>
        <Footer />
      </Router>
  </>
);
}

export default App;
